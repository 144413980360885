<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <br>
    <br>
    <br>
    <br>
    <!--<p-form showSearch>-->
      <!--<template v-slot:content>-->
        <v-container >
          <v-row justify="center">
            <v-col cols="12" >
              <h1>Resultado</h1>
            </v-col>

            <v-col cols="12" class="chipApu">
              <v-chip  v-for="(item, i) in apuracaoJogo" :key="'linha'+i"><h1>{{zeroEsquerda(item,2)}}</h1></v-chip>
            </v-col>
            <v-col cols="12">

              <simple-table :itens="listaCalculada" :cabecalho="cabecalho" @item=itens  :showAddButton="false">
                <template v-slot:[n.slot]="props" v-for="(n, index) in cabecalho" >
                  <div v-if="n.slot === 'jogo'" :key="'jogo'+index">
                    <v-chip  v-for="(item, i) in preencherJogo(props.item)" :key="'chip'+i" :class="getClasseAcertos(item)" >{{zeroEsquerda(item,2)}}</v-chip>
                  </div>
                  <div v-if="n.slot === 'acertos'" :key="'acertos'+index">
                    {{props.item.acertos}}
                  </div>
                  <div v-else :key="'outros'+index"><span style="text-transform: uppercase">{{props.item[n.slot]}}</span></div>
                </template>
               </simple-table>
            </v-col>
          </v-row>


        </v-container>
  </div>
</template>

<script>
/* eslint-disable */
  import {mapActions, mapGetters} from 'vuex'
  import paginacao from '../../helpers/paginacao'
  import miscMixin from '../../helpers/misc'
  import Cartao from '../../models/cartao'
  import {acaoApi} from '../../helpers/enums'
  import {EventBus} from '../../helpers/event-bus'

  export default {
    mixins: [miscMixin, paginacao],
    name: 'Cartao',
    data: () => ({
      cartao: [],
      meuJogo: [],
      apuracaoJogo: [],
      cartaoArray: [
        {linha: [1, 2, 3, 4, 5]},
        {linha: [6, 7, 8, 9, 10]},
        {linha: [11, 12, 13, 14, 15]},
        {linha: [16, 17, 18, 19, 20]},
        {linha: [21, 22, 23, 24, 25]},
      ],
      editando: false,
      removendo: false,
      dialogCadastro: false,
      show1: false,
      show2: false,
      loading: false,
      cabecalho: [
        {
          text: 'Nome',
          value: 'nome',
          align: 'left',
          slot: 'nome',
          sortable: true
        },
        {
          text: 'Jogo',
          value: 'jogo',
          align: 'left',
          slot: 'jogo',
          sortable: false
        },
        {
          text: 'Acertos',
          value: 'acertos',
          align: 'left',
          slot: 'acertos',
          sortable: true
        },
        // {
        //   text: 'Opções',
        //   value: 'actions',
        //   align: 'right',
        //   notBtn: true,
        //   sortable: false
        // }
      ],
    }),
    methods: {
      ...mapActions('apuracao', {setAllApuracaos: 'setAllApuracaos', setFiltroApuracao: 'setFiltro'}),
      // ...mapActions('apuracao', ['setAllApuracaos', 'setFiltro','setApuracao']),
      ...mapActions('cartao', ['setAllCartaos', 'setFiltro', 'searchItem', 'processarCartao']),
      getClasseAcertos(item) {
        var achou = this.apuracaoJogo.find(list=> list === item)
        return this.validarCampo(achou) ? 'is_selected' : 'is_not_selected'
      },
      calcularAcertos(item){
        var array = []
        var cont = 0
        for (var i = 0; i < 15; i++) {
          array.push(item['num' + (i + 1)])
          var achou = this.apuracaoJogo.find(list=> list === item['num' + (i + 1)])
          if (this.validarCampo(achou)) {
            cont++
          }
        }
      return cont


      },
      preencherJogo(item){
        var array = []
        for (var i = 0; i < 15; i++) {
          array.push(item['num' + (i + 1)])
        }
        return array
      },
      getClasse(num) {
        var achou = this.meuJogo.find(list => list === num)
        return this.validarCampo(achou) ? 'is_selected' : 'is_not_selected'
      },
      selecionar(num) {
        if (this.meuJogo.length <= 0) {
          this.meuJogo.push(num)
        } else {
          // if (this.meuJogo.length<15) {
          var achou = this.meuJogo.find(list => list === num)
          if (!this.validarCampo(achou)) {
            if (this.meuJogo.length < 15) {
              this.meuJogo.push(num)
            } else {
              alert('Voce atigiu o limite de numeros para selecionar')
            }
          } else {
            let pos = this.meuJogo.indexOf(achou)
            this.meuJogo.splice(pos, 1)
          }
        }
      },

      ordem(itemsOrder) {
        this.filtro.orders = []
        this.filtro.addOrder(itemsOrder.campo, itemsOrder.direction)
        this.carregarLista()
      },
      setPorPaginas(itemsPerPage) {
        this.filtro.perPage = this.itemsPerPage = itemsPerPage
      },
      pegarNovaLista(page) {
        this.setPage = false
        this.filtro.page = page
        this.filtro.perPage = this.itemsPerPage

        this.carregarLista()
      },

      carregarLista() {
        this.setAllCartaos({
          onSucesso: () => {
            EventBus.$emit('SHOW_LISTA', true)
          },
          onErro: () => {
            EventBus.$emit('SHOW_LISTA', false)
          }
        })
      },
      carregarListaApuracao() {
        this.setAllApuracaos({
          onSucesso: () => {
            EventBus.$emit('SHOW_LISTA', true)
            this.apuracaoJogo = []
            for (var i = 0; i < 15; i++) {
              this.apuracaoJogo.push(this.apuracao['num' + (i + 1)])
            }
          },
          onErro: () => {
            EventBus.$emit('SHOW_LISTA', false)
          }
        })
      }
    },
    computed: {
      ...mapGetters('cartao', {
        listaCartaos: 'listaCartaos',
        filtro: 'pegaFiltro'
      }),
      ...mapGetters('apuracao', {
        apuracao: 'pegaApuracao',
        // listaApuracaos: 'listaApuracaos',
        // filtro: 'pegaFiltro'
      }),
      listaCalculada(){


        // console.log(this.listaCartaos)
        var array = []
        if (this.validarCampo(this.listaCartaos.data)) {
        if (this.listaCartaos.data.length > 0) {

          // array = this.cloneObj(this.listaCartaos)
          // for(var i = 0; i<array.data.length; i++){
          //   array.data[i]['acertos'] = this.calcularAcertos(array.data[i])
          //   array.data[i]['jogo'] = ''
          // }
          // array.data = this.ordenarLista(array.data , 'acertos','desc')


          array = this.cloneObj(this.listaCartaos.data)
          for(var i = 0; i<array.length; i++){
            array[i]['acertos'] = this.calcularAcertos(array[i])
            array[i]['jogo'] = ''
          }
          array = this.ordenarLista(array , 'acertos','desc')

          // array = this.ordenarLista(array , 'nome','asc')
        }
        }


        return array
      }
    },
    created() {
      this.setFiltro(this.itemsPerPage)
      this.setFiltroApuracao(1)
      this.carregarLista()
      this.carregarListaApuracao()
    },
    mounted() {
    },
    watch: {
      itemsPerPage: function() {
        this.carregarLista()
      },
    },
  }
</script>
<style scoped lang="scss">
  .jogo {
    tr, td {
      padding: 10px;
      border: 1px solid #ccc;
      text-align: center;
      font-size: 20px;

    }

    table {
      border: 1px solid #ccc;
      padding: 5px;
      text-align: center;
    }
  }

</style>
